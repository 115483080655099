<template>
  <div>
    <ul class="biz-breadcrumb" v-if="!isRegistrationWizard">
      <li><router-link to="/supplier/manage">{{$t('SYSTEM_MANAGEMENT.TITLE')}}</router-link></li>
      <li><a>{{$t('CATEGORY.TITLE')}}</a></li>
    </ul>
    <div class="page">
      <div class="page-title" v-if="!isRegistrationWizard">
        <h1>{{$t('CATEGORY.TITLE')}}</h1>
      </div>

      <div class="card biz-primary mt-3">
        <div class="card-header">
          {{$t('CATEGORY.HEADER')}}
          <p>{{$t('CATEGORY.DESCRIPTION')}}</p>
        </div>
        <div class="card-body">
          <div style="border: 1px solid #b1b4b8;" class="p-2">
            <div class="row">
              <div class="col col-3 al-child-mid">
                <h5 style="font-size: 0.9rem; font-weight: bold; ">{{$t('CATEGORY.BULK_REGISTER')}}</h5>
              </div>
              <div class="col">
                <p style="font-size: 0.8rem;">{{$t('CATEGORY.BULK_REGISTER_DESC')}}</p>
              </div>
              <div class="col col-4 al-child-mid">
                <j-button class="" variant="light" size="sm" @click="onDownload">
                  <font-awesome-icon icon="download"/>{{$t('COMMON.DOWNLOAD')}}
                </j-button>
                <j-button variant="primary" size="sm" class="ml-2" @click="$refs['upload-category-modal'].show()">
                  <font-awesome-icon icon="upload"/>{{$t('COMMON.UPLOAD')}}
                </j-button>
                <b-modal ref="upload-category-modal" :title="$t('CATEGORY.UPLOAD_BUTTON')" @ok="onUpload">
                  <b-form-group label="">
                    <b-form-file v-model="uploadFile"></b-form-file>
                  </b-form-group>
                </b-modal>
              </div>
            </div>
          </div>
          <div id="top-category-container" class="mt-2 bg-white p-4">
            <h3 class="text-center" style="font-size: 1.5rem;">{{$t('CATEGORY.STEP_1_TITLE')}}</h3>
            <div class="grid mt-3">
              <button 
                v-for="cat in categories"
                v-bind:key="cat.id"
                :class="{'grid-button': true, 'active': cat.updated}"
                @click.prevent="onSelect(cat, true)">
                {{cat.categoryName}}
              </button>
            </div>
          </div>
          <div id="mid-category-container" class="mt-2 bg-white p-4" v-if="categories.filter(c => c.updated).length > 0">
            <h3 class="text-center" style="font-size: 1.5rem;">{{$t('CATEGORY.STEP_2_TITLE')}}</h3>
            <div class="mt-3" v-for="large in categories.filter(c => c.updated)" v-bind:key="large.id">
              <h4 style="font-size: 1.25rem; float:left;">{{large.categoryName}}</h4>
              <a href="#" style="float:right;" @click.prevent="large.children.forEach(c => $set(c, 'updated', true))">{{$t('COMMON.SELECT_ALL')}}</a>
              <hr style="border-top: 1px solid #4169E1; margin-top: 0.25rem; clear:both;"/>
              <div class="grid mt-3">
                <button 
                  :class="{'grid-button': true, 'active': cat.updated}"
                  v-for="cat in large.children"
                  @click.prevent="onSelect(cat)"
                  v-bind:key="cat.id">
                  {{cat.categoryName}}
                </button>
              </div>
            </div>
            <div class="text-right mt-2">
              <a href="#top-category-container"><font-awesome-icon icon="caret-up"/> {{$t('CATEGORY.GO_TO_STEP_1')}}</a>
            </div>
          </div>
           <div class="mt-2 bg-white p-4" v-if="categories.filter(c => (c.updated) && c.children && c.children.filter(m => m.updated).length > 0).length > 0">
            <h3 class="text-center" style="font-size: 1.5rem;">{{$t('CATEGORY.STEP_3_TITLE')}}</h3>
            <div v-for="large in categories.filter(c => c.updated)" v-bind:key="large.id">
              <div class="mt-3" v-for="mid in large.children.filter(c => c.updated)" :key="mid.id">
                <h4 style="font-size: 1.25rem; float:left;">{{large.categoryName}} > {{mid.categoryName}}</h4>
                <a href="#" style="float:right;" @click.prevent="mid.children.forEach(c => $set(c, 'updated', true))">{{$t('COMMON.SELECT_ALL')}}</a>
                <hr style="border-top: 1px solid #4169E1; margin-top: 0.25rem; clear:both;"/>
                <div class="p-2" style="background: #FFF4CD;" v-if="mid.children.filter(c => c.license).length > 0">
                  <p class="m-0">{{$t('CATEGORY.NEED_VERIFICATION') }}</p>
                </div>
                <div class="grid mt-3">
                  <button 
                    :class="{'grid-button': true, 'active': cat.updated}"
                    v-for="cat in mid.children"
                    @click.prevent="onSelect(cat)"
                    v-bind:key="cat.id">
                    {{cat.categoryName}}
                    <span v-if="cat.license" class="badge badge-warning ml-1">認</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="text-right mt-2">
              <a href="#mid-category-container"><font-awesome-icon icon="caret-up"/> {{$t('CATEGORY.GO_TO_STEP_2')}}</a>
            </div>
          </div>
          <div class="mt-3">
            <a href="#" class="btn btn-light" @click.prevent="onShowPreview()">{{$t('COMMON.PREVIEW')}}</a>
            <b-modal ref="tree-view" hide-footer :title="$t('COMMON.PREVIEW')" >
              <category-list :categories="preview" @onOpen="$set($event, 'isOpen', !$event.isOpen)"/>
            </b-modal>
          </div>
        </div>
      </div>
      
      <div class="text-center mt-5">
        <j-button variant="primary" size="lg" v-on:click="onSave">
          {{isRegistrationWizard?$t('COMMON.NEXT'):$t('COMMON.SAVE')}}
        </j-button>
        <div class="mt-4" v-if="!isRegistrationWizard">
          <j-button variant="light" to="/supplier/manage">
            <font-awesome-icon icon="arrow-left"></font-awesome-icon>
            {{$t('COMMON.GO_BACK')}}
          </j-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .grid {
    display:grid;
    grid-template: "a a a a a"
                   "a a a a a";
    grid-auto-columns: 1fr;               
    .grid-button{
      margin: .75rem .5rem;
      padding: 1rem .5rem;
      background: #FFF;
      border-radius: .5rem;
      border: 2px solid #CCCCCC;
      transition: all 140ms ease-in;
      &:hover, &.active{
        border-color: $biz-primary;
        background: $biz-primary-light;
      }
    }       
  }

  .badge{
    background: #FFF;
    padding: 0 .5rem 0 .5rem;
    margin-left:-0.5em;
    &.badge-check{
      border: 1px solid $biz-primary;
      color: $biz-primary;
    }
    &.badge-warning{
      border: 1px solid $biz-danger;
      color: $biz-danger;
    }
    &.badge-dl{
      color: $biz-primary;
      background: inherit;
      padding: 0 0 0 0;
      text-decoration: underline;
    }
  }
</style>
<script>
  
  import Category from '@/model/Category';
  import CompanyInfo from '@/model/CompanyInfo';
  import CategoryList from '@/components/category/CategoryList';

  export default {
    props: ["isRegistrationWizard"],
    components: { CategoryList },
    data: () => ({
      categories: [
        
      ],
      preview: [],
      keyword: "",
      uploadFile: null,
    }),
    mounted: function(){
      this.load();
    },
    methods:{
      load(){
        CompanyInfo.getCompanyCategory(this.$store.state.user.companyCode).then(result => {
          this.categories = result.data;
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      },
      async onSave(){
        let popupRes = true;
        if(this.$store.state.user && this.$store.state.user.status.registrationCompleted){
          popupRes = await this.$bvModal.msgBoxConfirm(this.$t('CATEGORY.CONFIRM_MESSAGE'), {
            okTitle: this.$t('COMMON.UPDATE'),
            cancelTitle: this.$t('COMMON.CANCEL')
          })
        }
        if(popupRes){ 
          let data = Category.toCompanyCategory(this.categories);
          if(Category.countCompanyCategory(data) > 0){
            CompanyInfo.saveCompanyCategory(this.$store.state.user.companyCode, data).then(() => {
              this.$successMessage(this.$t('CATEGORY.SUCCESS_MESSAGE'));
              this.$emit('onSave', 'CategoryInfo');
              if(!this.isRegistrationWizard){
                this.load();
              }
            }).catch(reason => console.error(reason));
          }else{
            this.$errorMessage(this.$t('CATEGORY.MINIMUM_ERROR_MESSAGE'));
          }
        }
      },
      onSelect(category, isLoad = false){
        this.$set(category, 'updated', !category.updated);
        if(isLoad)
          Category.getChildCategory(category.id).then((result) => {
            this.$set(category, 'children', result.data.children);
          }).catch(reason => this.$errorMessage(undefined, {reason}));
      },
      onDownload(){
        Category.downloadCategory(this.$store.state.user.companyCode).then(result => {
          const blob = new Blob([result.data], {type: "application/xlsx"});
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = decodeURI(result.headers['x-file-name']);
          link.click();
          URL.revokeObjectURL(link.href);
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      },

      onUpload(){
        Category.uploadCategory(this.$store.state.user.companyCode, this.uploadFile).then(() => {
          this.$successMessage(this.$t('CATEGORY.UPLOAD_SUCCESS_MESSAGE'));
          this.load();
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      },
      onShowPreview(){
        const filterData = (data) => data.filter(o => {
          if (o.children) o.children = filterData(o.children);
          return o.updated || o.registered;
        });
        this.preview = JSON.parse(JSON.stringify(this.categories));
        this.preview = filterData(this.preview);
        
        this.$refs['tree-view'].show();
      }
    }
  }
</script>